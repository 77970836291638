import React, { createRef } from 'react';
import styled from 'styled-components';

const IconAdd = new Path2D(
	'M-7-12.428H7.163a1.61,1.61,0,0,0-.409-.888,1.3,1.3,0,0,0-.962-.316H-5.634a1.3,1.3,0,0,0-.962.316A1.61,1.61,0,0,0-7-12.428Zm1.626-2.355H5.528a1.193,1.193,0,0,0-.36-.835,1.319,1.319,0,0,0-.905-.281H-4.1a1.316,1.316,0,0,0-.91.281A1.234,1.234,0,0,0-5.379-14.783ZM6.363-11.065H-6.214a2.737,2.737,0,0,0-2.008.668A2.672,2.672,0,0,0-8.895-8.42v3.27q.185-.026.374-.04t.382-.013q.185,0,.374.018t.382.044V-8.306a1.253,1.253,0,0,1,.321-.932,1.24,1.24,0,0,1,.91-.316H6.284a1.253,1.253,0,0,1,.918.316,1.253,1.253,0,0,1,.321.932V.316a1.263,1.263,0,0,1-.321.945,1.264,1.264,0,0,1-.918.312h-9.22a4.452,4.452,0,0,1-.25.778,5.453,5.453,0,0,1-.374.725H6.363a2.711,2.711,0,0,0,1.991-.668A2.672,2.672,0,0,0,9.026.431V-8.42A2.672,2.672,0,0,0,8.354-10.4,2.711,2.711,0,0,0,6.363-11.065ZM-8.13,4.482A4,4,0,0,0-6.5,4.144a4.421,4.421,0,0,0,1.358-.927,4.358,4.358,0,0,0,.923-1.358A4.07,4.07,0,0,0-3.885.22a4.139,4.139,0,0,0-.33-1.648,4.332,4.332,0,0,0-.914-1.358,4.291,4.291,0,0,0-1.358-.923A4.1,4.1,0,0,0-8.13-4.043,4.046,4.046,0,0,0-9.778-3.7a4.46,4.46,0,0,0-1.358.923,4.3,4.3,0,0,0-.923,1.354A4.092,4.092,0,0,0-12.393.22a4.092,4.092,0,0,0,.334,1.648,4.358,4.358,0,0,0,.923,1.358,4.358,4.358,0,0,0,1.358.923A4.092,4.092,0,0,0-8.13,4.482ZM-10.819.22a.531.531,0,0,1,.149-.4.544.544,0,0,1,.4-.145h1.6V-1.916a.535.535,0,0,1,.149-.4.523.523,0,0,1,.387-.149.535.535,0,0,1,.4.149.535.535,0,0,1,.149.4V-.325h1.6a.544.544,0,0,1,.4.145.531.531,0,0,1,.149.4.535.535,0,0,1-.149.4.535.535,0,0,1-.4.149h-1.6v1.6a.523.523,0,0,1-.149.387.535.535,0,0,1-.4.149.523.523,0,0,1-.387-.149.523.523,0,0,1-.149-.387V.765h-1.6a.535.535,0,0,1-.4-.149A.535.535,0,0,1-10.819.22Z'
);

const StyledCanvas = styled.canvas`
	position: absolute;
	left: 0;
	top: 0;
	box-sizing: border-box;
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.5s ease;
`;

export default class ProgressRing extends React.Component {
	constructor(props) {
		super(props);

		this.canvasRef = createRef();
		this.animationRef = createRef();
		this.pixelRatio = window.devicePixelRatio || 1;

		this.update = this.update.bind(this);
		this.loop = this.loop.bind(this);
		this.clear = this.clear.bind(this);
		this.resize = this.resize.bind(this);

		this.progress = 0;
		this.checkpoint = 0;
		this.visible = false;
	}

	componentDidUpdate() {
		window.removeEventListener('resize', this.resize);
		cancelAnimationFrame(this.animationRef.current);

		this.context = this.canvasRef.current.getContext('2d');

		this.resize();
		this.addListeners();
		this.loop();
	}

	clear() {
		const { context } = this;

		context.setTransform(1, 0, 0, 1, 0, 0);

		context.clearRect(
			0,
			0,
			this.canvasRef.current.width,
			this.canvasRef.current.height
		);
	}

	update() {
		this.clear();

		if (this.visible) {
			this.canvasRef.current.style.opacity = 1;
		} else {
			this.canvasRef.current.style.opacity = 0;
		}

		if (!this.visible) return;

		const { context } = this;

		context.translate(
			this.canvasRef.current.width / 2,
			this.canvasRef.current.height / 2
		);

		context.rotate(-Math.PI / 2 - Math.PI * 1.4 * this.progress);

		context.lineWidth = 8 * this.pixelRatio;
		context.lineCap = 'round';
		context.strokeStyle = 'rgba(255, 255, 255, 0.5)';

		context.beginPath();
		context.arc(
			0,
			0,
			this.canvasRef.current.width / 2 - 4 * this.pixelRatio,
			0,
			Math.PI * 1.4
		);
		context.stroke();

		context.strokeStyle = 'rgb(255, 255, 255)';

		context.beginPath();
		context.arc(
			0,
			0,
			this.canvasRef.current.width / 2 - 4 * this.pixelRatio,
			0,
			Math.max(0.001, Math.PI * 1.4 * this.progress)
		);
		context.stroke();

		if (this.checkpoint > 0) {
			context.fillStyle = 'rgb(0%, 93.7%, 61.2%)';

			const ca = Math.cos(this.checkpoint * Math.PI * 1.4);
			const sa = Math.sin(this.checkpoint * Math.PI * 1.4);

			const cpx = ca * (this.canvasRef.current.width / 2 - 4 * this.pixelRatio);
			const cpy =
				sa * (this.canvasRef.current.height / 2 - 4 * this.pixelRatio);

			const cpbx =
				ca * (this.canvasRef.current.width / 2 - 50 * this.pixelRatio);
			const cpby =
				sa * (this.canvasRef.current.height / 2 - 50 * this.pixelRatio);

			context.beginPath();
			context.arc(cpx, cpy, 4 * this.pixelRatio, 0, 2 * Math.PI);
			context.fill();

			context.beginPath();
			context.arc(cpbx, cpby, 20 * this.pixelRatio, 0, 2 * Math.PI);
			context.fill();

			context.fillStyle = 'rgb(255, 255, 255)';

			context.translate(cpbx, cpby);
			context.rotate(Math.PI / 2 + Math.PI * 1.4 * this.progress);
			context.scale(this.pixelRatio, this.pixelRatio);
			context.translate(1, 6);
			context.fill(IconAdd);
		}
	}

	loop() {
		this.update();

		this.animationRef.current = requestAnimationFrame(this.loop);
	}

	resize() {
		this.canvasRef.current.width =
			this.canvasRef.current.parentNode.clientWidth * this.pixelRatio;
		this.canvasRef.current.height =
			this.canvasRef.current.parentNode.clientHeight * this.pixelRatio;
		this.canvasRef.current.style.width = `${this.canvasRef.current.parentNode.clientWidth}px`;
		this.canvasRef.current.style.height = `${this.canvasRef.current.parentNode.clientHeight}px`;
	}

	addListeners() {
		window.addEventListener('resize', this.resize);
	}

	render() {
		return <StyledCanvas ref={this.canvasRef} />;
	}
}
