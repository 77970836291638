import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Popover from './Popover';
import { setAboutPageActive } from '../actions/actions';

import ImageAboutA from '../graphics/image_about_a.png';
import ImageAboutB from '../graphics/image_about_b.png';
import ImageAboutC from '../graphics/image_about_c.png';
import ImageAboutD from '../graphics/image_about_d.png';
import ImageAboutE from '../graphics/image_about_e.png';

const StyledTable = styled.div`
	margin-top: 1em;

	.table-row {
		display: flex;
		margin-bottom: 1em;

		.table-cell:first-child {
			flex: 0 0 50px;
		}
	}
`;

function AboutPage(props) {
	const { active, dispatch } = props;

	return (
		<Popover
			name="Over Groef"
			active={active}
			onClose={() => dispatch(setAboutPageActive(false))}
		>
			<div style={{ padding: '25px' }}>
				<p>
					Groef is een nieuwe manier om de historische platencollectie van het
					Nederlands Instituut voor Beeld en Geluid digitaal te ontdekken. Groef
					is een initiatief van het Nederlands Instituut voor Beeld en Geluid en
					is in 2021 bedacht, ontworpen en ontwikkeld door ontwerpstudio&nbsp;
					<a href="https://superposition.cc" target="_blank">
						Superposition
					</a>
					.
				</p>
				<p>
					Groef nodigt uit om op ontdekking te gaan door de duizenden
					gedigitaliseerde platen van Beeld en Geluid. De rijke collectie bevat
					onder andere volksmuziek, jazz en cabaret uit de periode 1910-1960. Je
					kunt de nummers vrijuit beluisteren, verzamelen in je collectie en
					delen met je netwerk.
				</p>
				<p>Hoe gebruik ik Groef?</p>
				<StyledTable>
					<div className="table-row">
						<div className="table-cell">
							<img src={ImageAboutA} height="39" />
						</div>
						<div className="table-cell">
							<p>
								Ontdek de nummers door je device om zijn as te draaien. Zorg dat
								je device niet op stil staat!
							</p>
						</div>
					</div>
					<div className="table-row">
						<div className="table-cell">
							<img src={ImageAboutB} height="39" />
						</div>
						<div className="table-cell">
							<p>
								Voor het meest realistische resultaat leg je jouw smartphone op
								een platenspeler ingesteld op 45 toeren per minuut.
								Experimenteer gerust met andere draaiende objecten om de app te
								bedienen maar wees voorzichtig met je telefoon!
							</p>
						</div>
					</div>
					<div className="table-row">
						<div className="table-cell">
							<img src={ImageAboutC} height="39" />
						</div>
						<div className="table-cell">
							<p>
								Voeg nummers toe aan jouw collectie door ze minimaal 10 seconden
								af te spelen. Krijg jij de collectie compleet?
							</p>
						</div>
					</div>
					<div className="table-row">
						<div className="table-cell">
							<img src={ImageAboutD} height="39" />
						</div>
						<div className="table-cell">
							<p>
								Deel nummers met bekenden en help ze hun collectie uit te
								breiden.
							</p>
						</div>
					</div>
				</StyledTable>
				<p className="subheading">Disclaimer</p>
				<p>
					Beeld en Geluid biedt de streamingdienst Groef aan op basis van een
					licentie-overeenkomst met Buma/Stemra waarmee de collectie binnen
					Nederland beschikbaar gesteld kan worden. Het auteursrecht en naburige
					rechten blijven onverminderd het eigendom van de oorspronkelijke
					componisten en uitvoerenden.
				</p>
				<p>
					Groef kan titels en liedteksten bevatten die als aanstootgevend kunnen
					worden ervaren. Mocht je er één tegenkomen stuur ons dan gerust een
					bericht op &nbsp;
					<a href="mailto:info@beeldengeluid.nl">info@beeldengeluid.nl</a>
.
				</p>
				<p>
					De duizenden platen uit de collectie zijn op grote schaal
					gedigitaliseerd. De kwaliteit en het volume van de nummers varieert.
					Dit heeft te maken met de digitaliseringsmethode, maar ook de sterk
					variërende kwaliteit van de oude platen.
				</p>
			</div>
		</Popover>
	);
}

function mapStateToProps(state) {
	return {
		active: state.app.aboutPageActive,
	};
}

export default connect(mapStateToProps)(AboutPage);
