import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Howl } from 'howler';
import { connect } from 'react-redux';
import { generator } from './EffectCanvas';
import {
	addDeviceOrientationListener,
	removeDeviceOrientationListener,
} from '../lib/deviceOrientation';

import ButtonPrevious from '../graphics/btn_previous.svg';
import ButtonNext from '../graphics/btn_next.svg';
import IconAdd from '../graphics/icon_add.svg';
import { getItems, getRandomItem, getURLForItem } from '../lib/api';
import useAnimationFrame from '../hooks/useAnimationFrame';
import { saveToCollection, setActiveItem } from '../actions/actions';

const StyledPlayer = styled.div`
	width: 77%;
	max-width: 77%;
	padding-bottom: 77%;
	height: 0;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	backdrop-filter: blur(40px);
	-moz-backdrop-filter: blur(40px);
	border-radius: 1000px;

	.content {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		text-align: center;

		.metadata {
			width: 75%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			.title {
				font-size: 22px;
			}

			.artist {
				font-size: 16px;
				/* opacity: 0.65; */
			}
		}

		.controls {
			position: absolute;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;

			.previous-button {
				background-image: url(${ButtonPrevious});
			}

			.next-button {
				background-image: url(${ButtonNext});
			}

			.previous-button,
			.next-button {
				width: 30px;
				height: 30px;
				display: inline-block;
				vertical-align: middle;
				background-position: center;
				background-repeat: no-repeat;
			}

			.id {
				font-size: 16px;
				width: 110px;
				display: inline-block;
				vertical-align: middle;
			}
		}

		.progress {
			padding: 15px;
			box-sizing: content-box;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			display: none;
			transform: translate(-50%, -50%);
			pointer-events: none;

			& > .checkpoint-big {
				overflow: visible;
				position: absolute;
				transform: translate(-50%, -50%);
				opacity: 1;

				circle {
					fill: #00ef9c;
					stroke: none;
				}
			}

			& > .rings {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				overflow: visible;
				transform: rotate(-90deg);

				& > svg {
					overflow: visible;

					.ring {
						stroke-width: 8px;
						stroke-linecap: round;
						opacity: 0.25;
						stroke: #fff;
						fill: none;
						stroke-dasharray: 220% 10000;
					}

					.fill {
						stroke-width: 8px;
						stroke-linecap: round;
						stroke: #fff;
						fill: none;
					}

					g {
						svg {
							overflow: visible;

							.checkpoint-small {
								fill: #00ef9c;
								stroke: none;
							}
						}
					}
				}
			}

			.time {
				position: absolute;
				bottom: calc(100% + 5px);
				left: 50%;
				width: 30px;
				margin-left: -15px;
				height: 50px;
				line-height: 50px;
				font-size: 16px;

				&:after {
					content: '';
					position: absolute;
					display: block;
					left: 50%;
					top: 50%;
					height: 28px;
					width: 2px;
					margin-left: -1px;
					margin-top: -14px;
					background: #fff;
					border-radius: 100px;
				}

				.duration,
				.current-time {
					position: absolute;
					top: 0;
				}

				.duration {
					right: 0;
					transform: translateX(100%);
					opacity: 0.5;
				}

				.current-time {
					left: 0;
					transform: translateX(-100%);
				}
			}

			.speed {
				font-size: 16px;
				bottom: calc(100% + 90px);
				position: absolute;
				left: 50%;
				width: 200px;
				margin-left: -100px;

				.current-speed {
					font-size: 20px;
					margin-bottom: 8px;
				}
			}
		}
	}

	.debug {
		position: absolute;
		left: 0;
		top: 100%;
		border: 3px double peachpuff;
		width: 150px;
		line-height: 14px;
		background: indianred;
		font-size: 12px;
		padding: 10px;
		font-family: 'Courier New', Courier, monospace;
	}

	.scroller {
		overflow: hidden;
		height: 40px;
		display: flex;
		align-items: center;
		white-space: nowrap;
		padding: 0 20px;
		box-sizing: border-box;
		mask-image: linear-gradient(
			to right,
			#0000,
			#fff 20px,
			#fff calc(100% - 20px),
			#0000 100%
		);

		.scroller-content {
			margin: 0 auto;
			position: relative;
			display: inline-block;
			left: 0;
			transform: translateX(0);

			/* &.scroll {
				left: 100%;
				transform: translateX(-100%);
				animation-name: scroll;
				animation-timing-function: linear;
			}

			&.scroll-back {
				left: 0;
				transform: translateX(0);
				animation-name: scroll-back;
				animation-timing-function: linear;
			} */
		}
	}

	@keyframes scroll {
		0% {
			left: 0;
			transform: translateX(0);
		}

		100% {
			left: 100%;
			transform: translateX(-100%);
		}
	}

	@keyframes scroll-back {
		0% {
			left: 100%;
			transform: translateX(-100%);
		}

		100% {
			left: 0;
			transform: translateX(0);
		}
	}
`;

const Player = (props) => {
	console.log('Player loaded');

	const { activeID, collection, dispatch, disable } = props;

	const artistRef = useRef();
	const titleRef = useRef();
	const progressRef = useRef();
	const checkpointRef = useRef();

	const angles = useRef();
	const howl = useRef();

	const [currentTime, setCurrentTime] = useState(0);
	const [totalTime, setTotalTime] = useState(0);
	const [displayedRate, setDisplayedRate] = useState(0);
	const [progress, setProgress] = useState(0);
	const [checkpoint, setCheckpoint] = useState(-1);
	const [currentItem, setCurrentItem] = useState(null);
	const [checkpointReached, setCheckpointReached] = useState(false);
	const [itemInCollection, setItemInCollection] = useState(false);
	const [itemInitiallyInCollection, setItemInitiallyInCollection] = useState(
		false
	);

	const updatePlaybackRate = () => {
		if (angles.current.length < 2) return;

		const newestAngle = angles.current[0];
		const oldestAngle = angles.current[angles.current.length - 1];

		let deltaAngle = newestAngle.angle - oldestAngle.angle;
		const deltaTimestamp = newestAngle.timestamp - oldestAngle.timestamp;

		deltaAngle = ((deltaAngle + 180) % 360) - 180;

		const rate = deltaAngle / (deltaTimestamp / 1000 / 60) / 360;

		let playbackRate = rate / 45;

		playbackRate = Math.abs(playbackRate); // TODO: Playback also backwards

		setDisplayedRate(Math.round(Math.abs(rate)));

		if (!howl.current) return;

		if (playbackRate > 0.01) {
			if (!howl.current.playing()) howl.current.play();

			const currentRate = howl.current.rate();
			const targetRate = (playbackRate + currentRate * 2) / 3;

			howl.current.rate(targetRate);
		} else {
			howl.current.pause();
		}
	};

	const updateProgress = () => {
		if (!howl.current) return;

		const currentHowlTime = howl.current.seek();
		const totalHowlTime = howl.current.duration();

		if (totalHowlTime === 0) return;

		progressRef.current.style.display = 'block';

		const currentMinutes = Math.floor(currentHowlTime / 60);
		const totalMinutes = Math.floor(totalHowlTime / 60);

		setCurrentTime(
			`${currentMinutes}:${`00${Math.floor(
				currentHowlTime - currentMinutes * 60
			)}`.substr(-2, 2)}`
		);
		setTotalTime(
			`${totalMinutes}:${`00${Math.floor(
				totalHowlTime - totalMinutes * 60
			)}`.substr(-2, 2)}`
		);
		setProgress(currentHowlTime / totalHowlTime);

		if (itemInCollection) return;

		setCheckpoint(20 / totalHowlTime);

		if (!checkpointReached && currentHowlTime > 2) {
			generator.generate(0.5, 0.5, -0.385, 50);

			checkpointRef.current.style.transition =
				'top 2s cubic-bezier(.77,0,.34,1.02), left 2s cubic-bezier(.77,0,.34,1.02), transform 2s cubic-bezier(.32,-5,.1,1) , opacity 1.9s cubic-bezier(.97,.02,.98,.56)';

			requestAnimationFrame(() => {
				checkpointRef.current.style.left = '50%';
				checkpointRef.current.style.opacity = '0';
				checkpointRef.current.style.transform =
					'translate(-50%, -50%) scale(0.3)';
				checkpointRef.current.style.top = `calc(50% + ${
					window.innerHeight / 2 - 100
				}px)`;
			});

			setCheckpointReached(true);
			setItemInCollection(true);

			// requestAnimationFrame(() => {
			// 	dispatch(saveToCollection(currentItem.name));
			// });
		} else if (!checkpointReached) {
			const cpa =
				((20 - currentHowlTime) / totalHowlTime) * Math.PI * 2 * 0.7 -
				Math.PI / 2;

			const cpx = Math.cos(cpa) * 37 + 50;
			const cpy = Math.sin(cpa) * 37 + 50;

			checkpointRef.current.style.transition = '';
			checkpointRef.current.style.left = `${Math.round(cpx * 100) / 100}%`;
			checkpointRef.current.style.top = `${Math.round(cpy * 100) / 100}%`;
			checkpointRef.current.style.opacity = '1';
			checkpointRef.current.style.transform = 'translate(-50%, -50%) scale(1)';
		}
	};

	const scrollMetadata = () => {
		const now = Date.now();

		if (!artistRef.current.scroller || !titleRef.current.scroller) return;

		const everyoneReady =
			artistRef.current.scroller.ready && titleRef.current.scroller.ready;

		[artistRef.current, titleRef.current].forEach((element) => {
			if (!element.scroller) return;

			if (element.scroller.maxDistance >= 1) {
				if (!everyoneReady && element.scroller.ready) {
					element.scroller.pauseUntil = now + 2000;
				}

				if (now > element.scroller.pauseUntil) {
					if (element.scroller.distance > element.scroller.maxDistance) {
						element.scroller.direction = 0;
						element.scroller.pauseUntil = now + 2000;
						element.scroller.ready = true;
						element.scroller.distance = element.scroller.maxDistance;
					} else if (element.scroller.distance < 0) {
						element.scroller.direction = 1;
						element.scroller.ready = true;
						element.scroller.pauseUntil = now + 2000;
						element.scroller.distance = 0;
					} else if (element.scroller.direction) {
						element.scroller.distance += 0.3;
						element.scroller.ready = false;
					} else {
						element.scroller.distance -= 1;
						element.scroller.ready = false;
					}
				}
			} else {
				element.scroller.distance = 0;
				element.scroller.ready = true;
			}

			element.firstChild.style.transform = `translateX(${-element.scroller
				.distance}px)`;
		});
	};

	useAnimationFrame(() => {
		if (disable && howl.current) {
			howl.current.pause();
		} else {
			updateProgress();
			updatePlaybackRate();
			scrollMetadata();
		}
	}, [currentItem, checkpointReached, itemInCollection, disable]);

	useEffect(() => {
		if (activeID == null) return;

		getItems([activeID]).then((data) => {
			setCurrentItem(data[0]);

			if (collection.indexOf(activeID) > -1) {
				setItemInCollection(true);
				setItemInitiallyInCollection(true);
			} else {
				setItemInCollection(false);
				setItemInitiallyInCollection(false);
			}
		});
	}, [activeID]);

	useEffect(() => {
		if (!currentItem) return;

		getURLForItem(currentItem.name).then((url) => {
			if (howl.current) howl.current.unload();

			howl.current = new Howl({
				src: url,
				onload: () => {
					setCheckpointReached(false);
				},
			});
		});

		[artistRef.current, titleRef.current].forEach((element) => {
			const styles = window.getComputedStyle(element);
			const outerWidth =
				element.clientWidth -
				parseFloat(styles.paddingLeft) -
				parseFloat(styles.paddingRight);
			const innerWidth = element.firstChild.clientWidth;

			element.scroller = {
				inner: innerWidth,
				outer: outerWidth,
				direction: 1,
				distance: 0,
				maxDistance: Math.max(0, innerWidth - outerWidth),
				pauseUntil: 0,
				ready: true,
			};
		});
	}, [currentItem]);

	useEffect(() => {
		angles.current = [];

		const deviceOrientationCallback = (e) => {
			const currentAngle = e.alpha;
			const currentTimestamp = Date.now();

			angles.current.unshift({
				angle: currentAngle,
				timestamp: currentTimestamp,
			});

			if (angles.current.length > 40) {
				angles.current.pop();
			}
		};

		addDeviceOrientationListener(deviceOrientationCallback);

		return () => {
			removeDeviceOrientationListener(deviceOrientationCallback);
		};
	}, []);

	// //// FOR DEBUGGING ONLY!

	// useEffect(() => {
	// 	window.addEventListener('keydown', () => {
	// 		if (!howl.current) return;

	// 		howl.current.rate(3);
	// 		howl.current.volume(0);

	// 		if (!howl.current.playing()) howl.current.play();
	// 	});

	// 	window.addEventListener('keyup', () => {
	// 		if (!howl.current) return;

	// 		if (howl.current.playing()) howl.current.pause();
	// 	});
	// }, []);

	// useEffect(() => {

	// }, [currentItem]);

	return (
		<StyledPlayer>
			<div className="content">
				<div className="metadata">
					<div className="title scroller" ref={titleRef}>
						<div className="scroller-content">
							{currentItem ? currentItem.title : ''}
						</div>
					</div>
					<div className="artist scroller" ref={artistRef}>
						<div className="scroller-content">
							{currentItem ? currentItem.artist : ''}
						</div>
					</div>
				</div>
				<div className="controls">
					<div
						className="previous-button"
						onClick={() => {
							if (howl.current) howl.current.seek(0);
						}}
					/>
					<div className="id">
						{`No. ${`0000${currentItem ? currentItem.id : ''}`.substr(-4, 4)}`}
					</div>
					<div
						className="next-button"
						onClick={() => {
							getRandomItem().then((data) => {
								dispatch(setActiveItem(data.name));
							});
						}}
					/>
				</div>
				<div className="progress" ref={progressRef}>
					<svg
						className="rings"
						xmlns="http://www.w3.org/2000/svg"
						style={{
							transform: `rotate(-90deg) rotate(${
								Math.round(-0.7 * progress * 360 * 100) / 100
							}deg)`,
						}}
					>
						<svg x="50%" y="50%">
							<circle className="ring" cx="0" cy="0" r="50%" />
							<circle
								className="fill"
								cx="0"
								cy="0"
								r="50%"
								strokeDasharray={`${
									Math.round(progress * 220 * 100) / 100
								}% 10000`}
							/>
							{!checkpointReached && !itemInCollection && (
								<g
									style={{
										transform: `rotate(${
											Math.round(0.7 * checkpoint * 360 * 100) / 100
										}deg)`,
									}}
								>
									<svg x="50%" y="0">
										<g>
											<circle
												className="checkpoint-small"
												cx="0"
												cy="0"
												r="4"
											/>
										</g>
									</svg>
								</g>
							)}
						</svg>
					</svg>
					{!itemInitiallyInCollection && (
						<svg
							className="checkpoint-big"
							width="40"
							height="40"
							ref={checkpointRef}
						>
							<circle cx="20" cy="20" r="20" />
							<image href={IconAdd} x="9.2905" y="9.809" />
						</svg>
					)}
					<div className="time">
						<div className="current-time">{currentTime}</div>
						<div className="duration">{totalTime}</div>
					</div>

					<div className="speed">
						<div className="current-speed">
							{displayedRate}
							/45
						</div>
						<div className="label">toeren</div>
					</div>
				</div>
			</div>
		</StyledPlayer>
	);
};

function mapStateToProps(state) {
	return {
		activeID: state.activeItem,
		collection: state.collection,
		disable: state.app.aboutPageActive || state.app.collectionPageActive,
	};
}

export default connect(mapStateToProps)(Player);
