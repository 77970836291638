import React, { createRef } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { setSearchQuery } from '../actions/actions';
import IconSearch from '../graphics/icon_search.svg';
import ButtonClose from '../graphics/btn_close.svg';

const StyledSearchBar = styled.input`
	appearance: none;
	margin: 0;
	padding: 0;
	border: 0;
	display: block;
	width: 100%;
	height: 35px;
	font-size: 15px;
	font-family: inherit;
	border-radius: 9px;
	padding: 0 10px 0 40px;
	box-sizing: border-box;
	color: #fff;
	background-color: rgba(255, 255, 255, 0.15);
	background-image: url(${IconSearch});
	background-repeat: no-repeat;
	background-position: 12px center;

	&::placeholder {
		color: rgba(255, 255, 255, 0.5);
	}

	outline: none;
`;

const StyledClearButton = styled.img`
	position: absolute;
	right: 10px;
	top: 50%;
	margin-top: -10px;
	width: 20px;
	height: 20px;
`;

function SearchBar(props) {
	const { dispatch, collectionSize, searchQuery } = props;
	const ref = createRef();

	function onInput() {
		dispatch(setSearchQuery(ref.current.value));
	}

	return (
		<div style={{ position: 'relative' }}>
			<StyledSearchBar
				ref={ref}
				onInput={onInput}
				placeholder={`Zoek in jouw collectie van ${collectionSize} nummers`}
			/>
			{searchQuery.length > 0 && (
				<StyledClearButton
					className="clear"
					src={ButtonClose}
					onClick={() => {
						ref.current.value = '';
						onInput();
					}}
				/>
			)}
		</div>
	);
}

function mapStateToProps(state) {
	return {
		collectionSize: state.collection.length,
		searchQuery: state.app.searchQuery,
	};
}

export default connect(mapStateToProps)(SearchBar);
