import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Howl } from 'howler';
import { saveToCollection, setActiveItem } from '../actions/actions';
import { getItems, getRandomItem, getURLForItem } from '../lib/api';
import ButtonPrevious from '../graphics/btn_previous.svg';
import ButtonNext from '../graphics/btn_next.svg';
import IconCheckmark from '../graphics/icon_checkmark.svg';
import useAnimationFrame from '../hooks/useAnimationFrame';
import {
	addDeviceOrientationListener,
	removeDeviceOrientationListener,
} from '../lib/deviceOrientation';
import { generator } from './EffectCanvas';
import ProgressRing from './ProgressRing';

const StyledNewPlayer = styled.div`
	width: 77vmin;
	max-width: 400px;
	/* padding-bottom: 77%; */
	height: 77vmin;
	max-height: 400px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	backdrop-filter: blur(30px);
	-moz-backdrop-filter: blur(30px);
	border-radius: 1000px;

	.content {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;

		text-align: center;

		.collection-marker {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin-top: -80px;

			.collection-marker-icon {
				display: block;
				margin-bottom: 8px;
			}

			.collection-marker-label {
				color: #00ffa7;
				font-size: 12px;
			}
		}

		.metadata {
			width: 75%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			.title {
				font-size: 22px;
			}

			.artist {
				font-size: 16px;
				opacity: 1;
			}
		}

		.controls {
			position: absolute;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;

			.previous-button {
				background-image: url(${ButtonPrevious});
			}

			.next-button {
				background-image: url(${ButtonNext});
			}

			.previous-button,
			.next-button {
				width: 30px;
				height: 30px;
				display: inline-block;
				vertical-align: middle;
				background-position: center;
				background-repeat: no-repeat;
			}

			.id {
				font-size: 16px;
				width: 110px;
				display: inline-block;
				vertical-align: middle;
			}
		}

		.progress {
			padding: 23px;
			box-sizing: content-box;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			pointer-events: none;

			.time {
				position: absolute;
				bottom: calc(100%);
				left: 50%;
				width: 20px;
				margin-left: -10px;
				height: 50px;
				line-height: 50px;
				font-size: 16px;
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

				&:after {
					content: '';
					position: absolute;
					display: block;
					left: 50%;
					top: 50%;
					height: 28px;
					width: 2px;
					margin-left: -1px;
					margin-top: -14px;
					background: #fff;
					border-radius: 100px;
				}

				.duration,
				.current-time {
					position: absolute;
					top: 0;
				}

				.duration {
					right: 0;
					transform: translateX(100%);
					opacity: 0.7;
				}

				.current-time {
					left: 0;
					transform: translateX(-100%);
				}
			}

			.rate {
				font-size: 16px;
				top: -100px;
				position: absolute;
				left: 50%;
				width: 200px;
				margin-left: -100px;
				text-shadow: 0 0 10px rgba(0, 0, 0, 0.6);

				.current-rate {
					font-size: 20px;
					margin-top: 8px;
				}
			}
		}
	}

	.scroller {
		overflow: hidden;
		height: 32px;
		display: flex;
		align-items: center;
		white-space: nowrap;
		padding: 0 20px;
		box-sizing: border-box;
		mask-image: linear-gradient(
			to right,
			#0000,
			#fff 20px,
			#fff calc(100% - 20px),
			#0000 100%
		);

		.scroller-content {
			margin: 0 auto;
			position: relative;
			display: inline-block;
			left: 0;
			transform: translateX(0);
		}
	}
`;

function shortestDistDegrees(start, stop) {
	const modDiff = (stop - start) % 360;
	const shortestDistance = 180 - Math.abs(Math.abs(modDiff) - 180);
	return (modDiff + 360) % 360 < 180 ? shortestDistance : shortestDistance * -1;
}

const NewPlayer = (props) => {
	const { activeID, collection, inCollection, disable, dispatch } = props;

	const [currentItem, setCurrentItem] = useState(null);

	const howl = useRef();
	const angles = useRef();

	const titleRef = useRef();
	const artistRef = useRef();
	const currentTimeRef = useRef();
	const currentTimeMillisRef = useRef();
	const totalTimeRef = useRef();
	const rateRef = useRef();
	const checkpointRef = useRef();
	const doneRef = useRef();
	const progressRef = useRef();

	const updatePlaybackRate = () => {
		if (angles.current.length < 2) return;

		const newestAngle = angles.current[0];
		const oldestAngle = angles.current[angles.current.length - 1];

		// let deltaAngle = newestAngle.angle - oldestAngle.angle;
		const deltaTimestamp = newestAngle.timestamp - oldestAngle.timestamp;

		// deltaAngle = ((deltaAngle + 180) % 360) - 180;

		const deltaAngle = shortestDistDegrees(
			oldestAngle.angle,
			newestAngle.angle
		);

		let rate = deltaAngle / (deltaTimestamp / 1000 / 60) / 360;

		if (rate < -35 && rate > -60) {
			rate = -45;
		} else if (rate > 35 && rate < 60) {
			rate = 45;
		}

		let playbackRate = (-1 * rate) / 45;

		if (!howl.current) return;

		if (
			(currentTimeMillisRef.current < 9000 && playbackRate < 0) ||
			(currentTimeMillisRef.current > howl.current.duration() * 1000 - 1000 &&
				playbackRate > 0)
		) {
			playbackRate = 0;
			rate = 0;
		}

		rateRef.current.innerHTML = Math.round(Math.abs(rate));

		if (playbackRate > 0.01 || playbackRate < -0.01) {
			if (!howl.current.playing()) {
				howl.current.seek(currentTimeMillisRef.current / 1000);
				howl.current.play();
			}

			const currentRate = howl.current.rate();
			const targetRate = (playbackRate + currentRate * 2) / 3;

			howl.current.rate(targetRate);
		} else {
			howl.current.pause();
		}
	};

	const updateProgress = (deltaTime) => {
		if (!howl.current) return;

		const currentRate = howl.current.rate();

		const totalHowlTime = howl.current.duration();

		if (howl.current.playing()) {
			currentTimeMillisRef.current += currentRate * deltaTime;
			currentTimeMillisRef.current = Math.max(
				Math.min(currentTimeMillisRef.current, totalHowlTime * 1000),
				8000
			);
		}

		const currentHowlTime = currentTimeMillisRef.current / 1000 - 8;

		const currentMinutes = Math.floor(currentHowlTime / 60);
		const totalMinutes = Math.floor((totalHowlTime - 8) / 60);

		currentTimeRef.current.innerHTML = `${currentMinutes}:${`00${Math.floor(
			currentHowlTime - currentMinutes * 60
		)}`.substr(-2, 2)}`;

		totalTimeRef.current.innerHTML = `${totalMinutes}:${`00${Math.floor(
			totalHowlTime - 8 - totalMinutes * 60
		)}`.substr(-2, 2)}`;

		if (inCollection || checkpointRef.current)
			progressRef.current.checkpoint = -1;
		else progressRef.current.checkpoint = 10 / totalHowlTime;

		progressRef.current.progress = currentHowlTime / (totalHowlTime - 9);

		if (!doneRef.current && progressRef.current.progress >= 1) {
			getRandomItem(collection).then((data) => {
				dispatch(setActiveItem(data.name));
			});
		}

		if (inCollection) return;

		if (!checkpointRef.current && currentHowlTime > 10) {
			checkpointRef.current = true;

			generator.generate.regular(0.5, 0.5, -0.385, 20);

			requestAnimationFrame(() => {
				dispatch(saveToCollection(currentItem.name));
			});
		}
	};

	const scrollMetadata = () => {
		const now = Date.now();

		if (!artistRef.current.scroller || !titleRef.current.scroller) return;

		const everyoneReady =
			artistRef.current.scroller.ready && titleRef.current.scroller.ready;

		[artistRef.current, titleRef.current].forEach((element) => {
			if (!element.scroller) return;

			if (element.scroller.maxDistance >= 1) {
				if (!everyoneReady && element.scroller.ready) {
					element.scroller.pauseUntil = now + 2000;
				}

				if (now > element.scroller.pauseUntil) {
					if (element.scroller.distance > element.scroller.maxDistance) {
						element.scroller.direction = 0;
						element.scroller.pauseUntil = now + 2000;
						element.scroller.ready = true;
						element.scroller.distance = element.scroller.maxDistance;
					} else if (element.scroller.distance < 0) {
						element.scroller.direction = 1;
						element.scroller.ready = true;
						element.scroller.pauseUntil = now + 2000;
						element.scroller.distance = 0;
					} else if (element.scroller.direction) {
						element.scroller.distance += 0.3;
						element.scroller.ready = false;
					} else {
						element.scroller.distance -= 1;
						element.scroller.ready = false;
					}
				}
			} else {
				element.scroller.distance = 0;
				element.scroller.ready = true;
			}

			element.firstChild.style.transform = `translateX(${-element.scroller
				.distance}px)`;
		});
	};

	useEffect(() => {
		if (activeID == null) return;

		getItems([activeID]).then((data) => {
			setCurrentItem(data[0]);
		});
	}, [activeID]);

	useEffect(() => {
		if (!currentItem) return;

		progressRef.current.visible = false;

		getURLForItem(currentItem.name).then((url) => {
			if (howl.current) howl.current.unload();

			howl.current = new Howl({
				src: url,
				onload: () => {
					currentTimeMillisRef.current = 0;
					checkpointRef.current = false;
					doneRef.current = false;
					progressRef.current.visible = true;

					setTimeout(() => {
						howl.current.seek(8);
					}, 100);
				},
			});

			window.addEventListener('blur', () => {
				howl.current.mute(true);
			});

			window.addEventListener('focus', () => {
				howl.current.mute(false);
			});

			currentTimeMillisRef.current = 8000;
		});

		[artistRef.current, titleRef.current].forEach((element) => {
			const styles = window.getComputedStyle(element);
			const outerWidth =
				element.clientWidth -
				parseFloat(styles.paddingLeft) -
				parseFloat(styles.paddingRight);
			const innerWidth = element.firstChild.clientWidth;

			element.scroller = {
				inner: innerWidth,
				outer: outerWidth,
				direction: 1,
				distance: 0,
				maxDistance: Math.max(0, innerWidth - outerWidth),
				pauseUntil: 0,
				ready: true,
			};
		});
	}, [currentItem]);

	useAnimationFrame(
		(e) => {
			if (disable) {
				if (howl.current) {
					howl.current.rate(0);
				}

				return;
			}

			scrollMetadata();
			updatePlaybackRate();
			updateProgress(e);
		},
		[currentItem, disable]
	);

	useEffect(() => {
		angles.current = [];

		const deviceOrientationCallback = (e) => {
			const currentAngle = e.alpha;
			const currentTimestamp = Date.now();

			angles.current.unshift({
				angle: currentAngle,
				timestamp: currentTimestamp,
			});

			if (angles.current.length > 10) {
				angles.current.pop();
			}
		};

		addDeviceOrientationListener(deviceOrientationCallback);

		return () => {
			removeDeviceOrientationListener(deviceOrientationCallback);
		};
	}, []);

	return (
		<StyledNewPlayer>
			<div className="content">
				{inCollection && (
					<div className="collection-marker">
						<div className="collection-marker-icon">
							<img src={IconCheckmark} />
						</div>
						<div className="collection-marker-label">In jouw collectie</div>
					</div>
				)}
				<div className="metadata">
					<div className="title scroller" ref={titleRef}>
						<div className="scroller-content">
							{currentItem ? currentItem.title : ''}
						</div>
					</div>
					<div className="artist scroller" ref={artistRef}>
						<div className="scroller-content">
							{currentItem ? currentItem.artist : ''}
						</div>
					</div>
				</div>
				<div className="controls">
					<div
						className="previous-button"
						onClick={() => {
							if (howl.current) {
								howl.current.seek(8);
								currentTimeMillisRef.current = 8000;
							}
						}}
					/>
					<div className="id">
						{`No. ${`0000${currentItem ? currentItem.id : ''}`.substr(-4, 4)}`}
					</div>
					<div
						className="next-button"
						onClick={() => {
							getRandomItem(collection).then((data) => {
								dispatch(setActiveItem(data.name));
							});
						}}
					/>
				</div>
				<div className="progress">
					<ProgressRing ref={progressRef} />
					<div className="time">
						<div className="current-time">
							<span ref={currentTimeRef} />
						</div>
						<div className="duration">
							<span ref={totalTimeRef} />
						</div>
					</div>

					<div className="rate">
						<div className="label">toeren</div>

						<div className="current-rate">
							<span ref={rateRef} />
							/45
						</div>
					</div>
				</div>
			</div>
		</StyledNewPlayer>
	);
};

const mapStateToProps = (state) => ({
	inCollection: state.collection.indexOf(state.activeItem) > -1,
	activeID: state.activeItem,
	collection: state.collection,
	disable: state.app.aboutPageActive || state.app.collectionPageActive,
});

export default connect(mapStateToProps)(NewPlayer);
