import { createClient } from '@supabase/supabase-js';

let getURLForItem;
let getRandomItem;
let getItems;

function transformItem(item) {
	return {
		id: item['GROEF NR'],
		title: item['Segment Title'],
		name: item['Segment Filename'].replaceAll('.mp3', ''),
		artist: Array(8)
			.fill(0)
			.map((_, i) => item[`Executive ${i + 1}`])
			.filter((v) => v.length > 0)
			.join(' & '),
	};
}

if (process.env.NODE_ENV === 'production') {
	console.log('ENV: Production');
	const URL = 'https://groef.app/';

	getURLForItem = (id) =>
		new Promise((resolve) => {
			resolve(`${URL}audio/${id}.mp3`);
		});

	getRandomItem = (exclude = []) =>
		new Promise((resolve, reject) => {
			fetch(`${URL}random`, {
				method: 'POST',
				body: JSON.stringify({ exclude }),
			})
				.then((response) => response.json())
				.then((json) => {
					resolve(transformItem(json));
				})
				.catch(reject);
		});

	getItems = (ids) =>
		new Promise((resolve, reject) => {
			if (ids.length === 0) return resolve([]);

			fetch(`${URL}find`, {
				method: 'POST',
				body: JSON.stringify({ ids }),
			})
				.then((response) => response.json())
				.then((json) => {
					resolve(json.map((item) => transformItem(item)));
				})
				.catch(reject);
		});
} else {
	console.log('ENV: Development');
	console.warn('-- WARNING: USING DEVELOPMENT API --');

	const supabaseUrl = 'https://jpxtvlytearxaxmpevbx.supabase.co';
	const supabaseKey =
		'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiYW5vbiIsImlhdCI6MTYxNzE5NTU1MywiZXhwIjoxOTMyNzcxNTUzfQ.rFPfH63jw1VUfrAv5IVa7D_ySengL5kMDXHk--WfpzM';

	const client = createClient(supabaseUrl, supabaseKey);

	getURLForItem = (id) =>
		new Promise((resolve, reject) => {
			client.storage
				.from('groef-test')
				.createSignedUrl(`${id}.mp3`, 60)
				.then((response) => {
					resolve(`${supabaseUrl}/storage/v1${response.data.signedURL}`);
				})
				.catch(reject);
		});

	getRandomItem = () =>
		new Promise((resolve, reject) => {
			client
				.from('random_view')
				.select('*')
				.then((response) => {
					const item = response.data[0];

					try {
						const json = JSON.parse(item.artist);
						item.artist = json.join(' & ');
					} catch (ignored) {}

					resolve(item);
				})
				.catch(reject);
		});

	getItems = (ids) =>
		new Promise((resolve, reject) => {
			if (ids.length === 0) return resolve([]);

			client
				.from('groef-test')
				.select('*')
				.in('name', ids)
				.then((response) => {
					const items = response.data.map((item) => {
						try {
							const json = JSON.parse(item.artist);
							item.artist = json.join(' & ');
						} catch (ignored) {}

						return item;
					});

					resolve(items);
				})
				.catch(reject);
		});
}

export { getURLForItem, getRandomItem, getItems };
