import React from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import ButtonShare from '../graphics/btn_share.svg';
import ButtonCollection from '../graphics/btn_collection.svg';
import ButtonAbout from '../graphics/btn_about.svg';

import {
	setAboutPageActive,
	setCollectionPageActive,
} from '../actions/actions';
import { getItems } from '../lib/api';

const StyledMenu = styled.div`
	position: absolute;
	left: 0;
	bottom: 0;
	height: 140px;
	width: 100%;
	display: grid;
	align-items: end;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	background: linear-gradient(to top, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));

	.menu-button {
		height: 72px;
		padding-bottom: 20px;
		display: flex;
		align-items: flex-end;
		justify-content: center;
		text-align: center;

		.button-label {
			margin-top: 10px;
			font-size: 12px;
		}
	}
`;

function Menu(props) {
	const { dispatch, collection, activeItem } = props;

	function share() {
		getItems([activeItem]).then((data) => {
			const item = data[0];

			let text;

			if (collection.length === 0) {
				text = `Ik heb nog geen nummers in mijn collectie op Groef, maar luister nu naar ${item.title}!`;
			} else if (collection.length === 1) {
				text = `Ik heb al één nummer in mijn collectie op Groef, en luister nu naar ${item.title}!`;
			} else {
				text = `Ik heb al ${collection.length} nummers in mijn collectie op Groef, en luister nu naar ${item.title}!`;
			}

			navigator.share({
				title: 'Groef',
				text,
				url: `https://groef.app/?item=${activeItem}`,
			});
		});
	}

	function openAboutPage() {
		dispatch(setAboutPageActive(true));
	}

	function openCollectionPage() {
		dispatch(setCollectionPageActive(true));
	}

	return (
		<StyledMenu>
			<div className="menu-button" onClick={share}>
				<div className="button-content">
					<div className="button-icon">
						<img src={ButtonShare} />
					</div>
					<div className="button-label">Delen</div>
				</div>
			</div>
			<div className="menu-button" onClick={openCollectionPage}>
				<div className="button-content">
					<div className="button-icon">
						<img src={ButtonCollection} />
					</div>
					<div className="button-label">Collectie</div>
				</div>
			</div>
			<div className="menu-button" onClick={openAboutPage}>
				<div className="button-content">
					<div className="button-icon">
						<img src={ButtonAbout} />
					</div>
					<div className="button-label">Over</div>
				</div>
			</div>
		</StyledMenu>
	);
}

function mapStateToProps(state) {
	return {
		activeItem: state.activeItem,
		collection: state.collection,
	};
}

export default connect(mapStateToProps)(Menu);
