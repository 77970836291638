export default {
	app: {
		collectionPageActive: false,
		aboutPageActive: false,
		searchQuery: '',
	},
	collection: [],
	activeItem: null,
	playingItem: null,
	enableTutorial: true,
};
