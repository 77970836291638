import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const StyledLevelIndicator = styled.div`
	font-size: 16px;
	padding: 0 10px;
	margin-top: 8px;

	.progress {
		height: 4px;
		width: 100%;
		background: rgba(255, 255, 255, 0.3);
		border-radius: 1000px;
		position: relative;
		margin-top: 9px;

		&:after {
			content: '';
			position: absolute;
			display: block;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3));
			opacity: 0.25;
			border-radius: 1000px;
		}

		.indicator {
			z-index: 1;
			height: 100%;
			background: #00ffa7;
			border-radius: 1000px;
			position: absolute;
			left: 0;
			top: 0;
			box-shadow: 3px 0 3px rgba(0, 0, 0, 0.15);

			&:after {
				content: '';
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(
					to top,
					rgba(255, 255, 255, 0),
					rgba(255, 255, 255, 1)
				);
				opacity: 0.5;
				border-radius: 1000px;
			}
		}
	}
`;

function LevelIndicator(props) {
	const { collectionSize } = props;

	const [level, setLevel] = useState(0);
	const [progress, setProgress] = useState(0);

	useEffect(() => {
		const l = (Math.sqrt(collectionSize * 4 + 1) + 1) / 2;

		setLevel(Math.floor(l));
		setProgress(l % 1);
	}, [collectionSize]);

	return (
		<StyledLevelIndicator>
			<div className="level">{`Level ${level}`}</div>
			<div className="progress">
				<div
					className="indicator"
					style={{
						width: `${Math.max(5, Math.round(progress * 10000) / 100)}%`,
					}}
				/>
			</div>
		</StyledLevelIndicator>
	);
}

function mapStateToProps(state) {
	return {
		collectionSize: state.collection.length,
	};
}

export default connect(mapStateToProps)(LevelIndicator);
