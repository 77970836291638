import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { connect, Provider } from 'react-redux';
import { createStore } from 'redux';
import { Howl } from 'howler';
import defaultState from './data/defaultState';

import ResetStyle from './components/ResetStyle';
import GlobalStyle from './components/GlobalStyle';
import Background from './components/Background';
import DeviceOrientationPrompt from './components/DeviceOrientationPrompt';
import {
	getDeviceOrientationStatus,
	initDeviceOrientation,
} from './lib/deviceOrientation';
import Player from './components/Player';
import EffectCanvas, { generator } from './components/EffectCanvas';
import Menu from './components/Menu';

import {
	SET_ABOUT_PAGE_ACTIVE,
	SET_COLLECTION_PAGE_ACTIVE,
	SET_SEARCH_QUERY,
	SET_ACTIVE_ITEM,
	SAVE_TO_COLLECTION,
	setActiveItem,
	SET_PLAYING_ITEM,
	SET_ENABLE_TUTORIAL,
	setEnableTutorial,
} from './actions/actions';
import AboutPage from './components/AboutPage';
import CollectionPage from './components/CollectionPage';
import { getRandomItem } from './lib/api';
import NewPlayer from './components/NewPlayer';

let { collection, enableTutorial } = window.localStorage;

if (!collection) {
	window.localStorage.collection = '[]';
	collection = '[]';
}

if (enableTutorial == null) {
	window.localStorage.enableTutorial = true;
	enableTutorial = true;
} else {
	enableTutorial = enableTutorial === 'true';
}

collection = JSON.parse(collection);
defaultState.collection = collection;
defaultState.enableTutorial = enableTutorial;

function reduce(state = defaultState, action) {
	switch (action.type) {
		case SAVE_TO_COLLECTION:
			const newCollection = [...state.collection];

			if (newCollection.indexOf(action.id) == -1) newCollection.push(action.id);
			window.localStorage.collection = JSON.stringify(newCollection);

			const p = ((Math.sqrt(newCollection.length * 4 + 1) + 1) / 2) % 1;

			if (p === 0) {
				setTimeout(() => generator.generate.floating(35), 2500);
			}

			return {
				...state,
				collection: newCollection,
			};
		case SET_ACTIVE_ITEM:
			window.history.replaceState(null, document.title, `/?item=${action.id}`);

			return {
				...state,
				activeItem: action.id,
			};
		case SET_PLAYING_ITEM:
			return {
				...state,
				playingItem: action.id,
			};
		case SET_SEARCH_QUERY:
			return {
				...state,
				app: {
					...state.app,
					searchQuery: action.query,
				},
			};
		case SET_ABOUT_PAGE_ACTIVE:
			return {
				...state,
				app: {
					...state.app,
					aboutPageActive: action.active,
				},
			};
		case SET_ENABLE_TUTORIAL:
			window.localStorage.enableTutorial = action.enable;

			return {
				...state,
				enableTutorial: action.enable,
			};
		case SET_COLLECTION_PAGE_ACTIVE:
			return {
				...state,
				app: {
					...state.app,
					collectionPageActive: action.active,
				},
			};
		default:
			return state;
	}
}

const store = createStore(reduce);

const StyledApp = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: #000;
	overflow: hidden;
`;

const StyledInteractive = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	transform: scale(1);
	border-radius: 0;
	transition: transform 0.33s cubic-bezier(0.3, 0.7, 0.5, 1),
		border-radius 0.33s cubic-bezier(0.3, 0.7, 0.5, 1);
	transform-origin: 50% 0;

	&.dimmed {
		transform: translateY(35px) scale(0.9);
		border-radius: 9px;
	}
`;

const howl = new Howl({ src: 'http://0.0.0.0' });

const App = (props) => {
	const { dispatch, dim, collection, enableTutorial } = props;

	const [
		showDeviceOrientationPrompt,
		setShowDeviceOrientationPrompt,
	] = useState(true);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const item = params.get('item');

		if (item && item.length > 0) {
			dispatch(setActiveItem(item));
		} else {
			getRandomItem(collection).then((data) => {
				dispatch(setActiveItem(data.name));
			});
		}
	}, []);

	getDeviceOrientationStatus().then((status) => {
		if (status !== -1 && status !== 1) {
			setShowDeviceOrientationPrompt(true);
		} else {
			initDeviceOrientation();
		}
	});

	return (
		<StyledApp>
			<ResetStyle />
			<GlobalStyle />
			<StyledInteractive className={dim ? 'dimmed' : ''}>
				<Background />
				{!showDeviceOrientationPrompt && <NewPlayer />}
				<EffectCanvas />
				<Menu />
			</StyledInteractive>
			<AboutPage />
			<CollectionPage />
			{showDeviceOrientationPrompt && (
				<DeviceOrientationPrompt
					enableTutorial={enableTutorial}
					onResolve={(status) => {
						if (status == -1) {
							alert(
								'Groef werkt alleen met de bewegingssensor van je telefoon. Helaas ondersteunt dit apparaat geen bewegingssensor. Probeer het op een andere telefoon!'
							);
						} else if (status == 0) {
							alert(
								'Om Groef te gebruiken moet je toegang geven tot je bewegingssensor. Sluit je browser helemaal af en open hem opnieuw, dan kunnen we het opnieuw vragen!'
							);
						} else {
							dispatch(setEnableTutorial(false));
							setShowDeviceOrientationPrompt(false);
						}
					}}
				/>
			)}
		</StyledApp>
	);
};

function mapStateToProps(state) {
	return {
		dim: state.app.aboutPageActive || state.app.collectionPageActive,
		enableTutorial: state.enableTutorial,
		collection: state.collection,
	};
}

const ConnectedApp = connect(mapStateToProps)(App);

ReactDOM.render(
	<Provider store={store}>
		<ConnectedApp />
	</Provider>,
	document.querySelector('#app')
);

// DEBUG ONLY!
if (process.env.NODE_ENV === 'development') {
	// && !window.DeviceOrientationEvent) {
	let a = 0;
	let d = 0;

	function fake() {
		window.dispatchEvent(
			new CustomEvent('deviceorientation', { detail: { alpha: a } })
		);

		a += d;
		a += 360;
		a %= 360;

		requestAnimationFrame(fake);
	}

	requestAnimationFrame(fake);

	window.addEventListener('keydown', () => {
		d = -15;
		// generator.generate.floating(100);
		// Levelup;
	});

	window.addEventListener('keyup', () => {
		d = 0;
	});
}

const html = document.querySelector('html');

setInterval(() => {
	html.scrollTop = 0;
	html.scrollLeft = 0;
}, 500);

function deviceOrientation() {
	const { body } = document;

	switch (window.orientation) {
		case 90:
			body.classList = '';
			body.classList.add('landscapeCW');
			break;
		case -90:
			body.classList = '';
			body.classList.add('landscapeCCW');
			break;
		default:
			body.classList = '';
			body.classList.add('portrait');
			break;
	}
}

window.addEventListener('orientationchange', deviceOrientation);
deviceOrientation();

// setInterval(() => {
// 	generator.generate.floating(100);
// }, 3000);
