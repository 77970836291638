import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { addDeviceOrientationListener } from '../lib/deviceOrientation';
import BackgroundImage from '../graphics/background.png';

const SQRT_2 = 1.4142135624;

const StyledBackground = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	overflow: hidden;
	background: url(${BackgroundImage});
	background-size: 64px 64px;
	image-rendering: pixelated;
`;

const StyledBackgroundLayer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	opacity: 1;
	width: ${SQRT_2 * 100}vmax;
	height: ${SQRT_2 * 100}vmax;
	box-shadow: 10px 0px 25px rgba(0, 0, 0, 0.23);
	transform-origin: 0 0;
`;

let deviceOrientationCallback;

export default (props) => {
	const [transform, setTransform] = useState({
		x: 0,
		y: 0,
		r: 0,
	});

	useEffect(() => {
		deviceOrientationCallback = (e) => {
			setTransform({
				x: Math.floor((e.gamma / 90) * 600 * 100) / 100,
				y: Math.floor((e.beta / 90) * 600 * 100) / 100,
				r: Math.floor(e.alpha * 100) / 100,
			});
		};

		addDeviceOrientationListener(deviceOrientationCallback);

		return () => {
			if (deviceOrientationCallback) {
				window.removeEventListener(deviceOrientationCallback);
			}
		};
	}, []);

	return (
		<StyledBackground>
			<StyledBackgroundLayer
				style={{
					background:
						'linear-gradient(to top, rgb(100%, 44.7%, 34.5%), rgb(100%, 44.7%, 34.5%, 0.8) 33%)',
					transform: `
					rotate(${transform.r - 30}deg) translateY(${
						Math.cos((transform.r / 360) * Math.PI * 2) * -transform.y +
						Math.sin((transform.r / 360) * Math.PI * 2) * -transform.x
					}px)					
					translate(-50%, -100%)`,
				}}
			/>
			<StyledBackgroundLayer
				style={{
					background:
						'linear-gradient(to left, rgb(18.4%, 27.8%, 98.4%), rgb(18.4%, 27.8%, 98.4%, 0.75) 33%)',
					transform: `
					rotate(${transform.r - 30}deg) translateX(${
						Math.sin((transform.r / 360) * Math.PI * 2) * -transform.y +
						Math.cos((transform.r / 360) * Math.PI * 2) * -transform.x
					}px)					
					translate(-100%, -50%)`,
				}}
			/>
		</StyledBackground>
	);
};
