import React from 'react';
import styled from 'styled-components';
import ButtonClose from '../graphics/btn_close.svg';

const StyledPopover = styled.div`
	background: rgba(0, 0, 0, 0);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	transition: background 0.33s cubic-bezier(0.31, 1.07, 0.5, 1);
	z-index: 5;

	.popover-sheet {
		position: absolute;
		left: 0;
		top: 57px;
		bottom: 0;
		right: 0;
		background: #2f47fb;
		border-radius: 9px 9px 0 0;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0);
		transform: translateY(100%);
		transition: transform 0.5s cubic-bezier(0.31, 1.07, 0.5, 1);
		display: flex;
		flex-direction: column;

		.popover-header {
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			position: relative;
			padding: 0 25px;

			.popover-main-header {
				display: flex;
				height: 71px;
				align-items: center;
			}

			.popover-additional-header {
				& > * {
					margin-bottom: 15px;
				}
			}

			.popover-icon {
				flex: 0 0 30px;
				margin-right: 10px;
			}

			.popover-name {
				flex: 1;
				font-size: 23px;
			}

			.button-close {
				flex: 0 0 30px;
				margin-left: 10px;
			}
		}

		.popover-content {
			flex: 1;
			overflow: scroll;
			-webkit-overflow-scrolling: touch;
			mask-image: linear-gradient(
				to bottom,
				rgba(0, 0, 0, 1) calc(100% - 25px),
				rgba(0, 0, 0, 0) 100%
			);
		}
	}

	&.active {
		background: rgba(0, 0, 0, 0.5);
		pointer-events: all;

		.popover-sheet {
			box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
			transform: translateY(0);
		}
	}
`;

export default (props) => {
	const { children, active, name, icon, onClose, additionalHeader } = props;

	return (
		<StyledPopover className={active ? 'active' : ''}>
			<div className="popover-sheet">
				<div className="popover-header">
					<div className="popover-main-header">
						{icon && (
							<div className="popover-icon">
								<img src={icon} />
							</div>
						)}
						{name && <div className="popover-name">{name}</div>}
						<div className="button-close" onClick={onClose}>
							<img src={ButtonClose} />
						</div>
					</div>
					{additionalHeader && (
						<div className="popover-additional-header">{additionalHeader}</div>
					)}
				</div>
				<div className="popover-content">{children}</div>
			</div>
		</StyledPopover>
	);
};
