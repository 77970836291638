import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Popover from './Popover';
import { setCollectionPageActive, setPlayingItem } from '../actions/actions';
import IconCollection from '../graphics/icon_collection.svg';
import SearchBar from './SearchBar';
import LevelIndicator from './LevelIndicator';
import { getItems } from '../lib/api';
import CollectionItem from './CollectionItem';

function CollectionPage(props) {
	const { query, active, dispatch, collection } = props;
	const [unfilteredCollection, setUnfilteredCollection] = useState([]);
	const [filteredCollection, setFilteredCollection] = useState([]);

	useEffect(() => {
		getItems(collection).then((data) => {
			setUnfilteredCollection(data);
		});
	}, [collection]);

	useEffect(() => {
		if (query.length === 0) setFilteredCollection(unfilteredCollection);
		else {
			const filtered = unfilteredCollection.filter((item) =>
				Object.keys(item)
					.filter((key) => key !== 'name')
					.reduce(
						(p, key) =>
							p ||
							`${item[key]}`.toLowerCase().indexOf(query.toLowerCase()) > -1,
						false
					)
			);

			setFilteredCollection(filtered);
		}
	}, [query, unfilteredCollection]);

	function generateItems(unfiltered, filtered) {
		return unfiltered.map((item) => (
			<CollectionItem
				key={item.id}
				id={item.id}
				artist={item.artist}
				title={item.title}
				name={item.name}
				visible={filtered.indexOf(item) > -1}
			/>
		));
	}

	return (
		<Popover
			name="Mijn Collectie"
			active={active}
			icon={IconCollection}
			onClose={() => {
				dispatch(setCollectionPageActive(false));
				// dispatch(setPlayingItem(null));
			}}
			additionalHeader={[<LevelIndicator key="li" />, <SearchBar key="lb" />]}
		>
			<div style={{ padding: '0 0 25px 0' }}>
				{generateItems(unfilteredCollection, filteredCollection)}
			</div>
		</Popover>
	);
}

function mapStateToProps(state) {
	return {
		active: state.app.collectionPageActive,
		query: state.app.searchQuery,
		collection: state.collection,
	};
}

export default connect(mapStateToProps)(CollectionPage);
