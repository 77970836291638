export const SET_ENABLE_TUTORIAL = 'SET_ENABLE_TUTORIAL';

export function setEnableTutorial(enable) {
	return {
		type: SET_ENABLE_TUTORIAL,
		enable,
	};
}

//

export const SET_ABOUT_PAGE_ACTIVE = 'SET_ABOUT_PAGE_ACTIVE';

export function setAboutPageActive(active) {
	return {
		type: SET_ABOUT_PAGE_ACTIVE,
		active,
	};
}

//

export const SET_COLLECTION_PAGE_ACTIVE = 'SET_COLLECTION_PAGE_ACTIVE';

export function setCollectionPageActive(active) {
	return {
		type: SET_COLLECTION_PAGE_ACTIVE,
		active,
	};
}

//

export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';

export function setSearchQuery(query) {
	return {
		type: SET_SEARCH_QUERY,
		query,
	};
}

//

export const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';

export function setActiveItem(id) {
	return {
		type: SET_ACTIVE_ITEM,
		id,
	};
}

//

export const SAVE_TO_COLLECTION = 'SAVE_TO_COLLECTION';

export function saveToCollection(id) {
	return {
		type: SAVE_TO_COLLECTION,
		id,
	};
}

//

export const SET_PLAYING_ITEM = 'SET_PLAYING_ITEM';

export function setPlayingItem(id) {
	return {
		type: SET_PLAYING_ITEM,
		id,
	};
}
