let initialized = false;

const listeners = [];

const listen = () => {
	window.addEventListener('deviceorientation', (e) => {
		listeners.forEach((listener) => {
			listener(e.detail || e);
		});
	});
};

export const getDeviceOrientationStatus = () =>
	new Promise((resolve) => {
		if (process.env.NODE_ENV === 'development' && !window.TouchEvent)
			resolve(1);
		else if (!window.DeviceOrientationEvent || !('ontouchstart' in window))
			resolve(-1);
		else if (typeof DeviceOrientationEvent.requestPermission === 'function') {
			DeviceOrientationEvent.requestPermission()
				.then((permissionState) => {
					if (permissionState === 'granted') resolve(1);
					else resolve(0);
				})
				.catch(() => {
					resolve(0);
				});
		} else {
			resolve(1);
		}
	});

export const initDeviceOrientation = () => {
	if (process.env.NODE_ENV === 'development' && !window.TouchEvent) {
		listen();
		initialized = true;

		return getDeviceOrientationStatus();
	}

	if (initialized) return getDeviceOrientationStatus();

	if (!window.DeviceOrientationEvent) return getDeviceOrientationStatus();

	if (typeof DeviceOrientationEvent.requestPermission === 'function') {
		DeviceOrientationEvent.requestPermission().then((permissionState) => {
			if (permissionState === 'granted') listen();
		});
	} else {
		listen();
	}

	initialized = true;

	return getDeviceOrientationStatus();
};

export const addDeviceOrientationListener = (cb) => {
	listeners.push(cb);
};

export const removeDeviceOrientationListener = (cb) => {
	const index = listeners.indexOf(cb);

	if (index > -1) {
		listeners.splice(index, 1);
	}
};
