import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { connect } from 'react-redux';
import { Howl, Howler } from 'howler';
import ButtonActivate from '../graphics/btn_activate.svg';
import ButtonPlay from '../graphics/btn_play.svg';
import ButtonShareSmall from '../graphics/btn_share_small.svg';
import ButtonPause from '../graphics/btn_pause.svg';
import {
	setActiveItem,
	setCollectionPageActive,
	setPlayingItem,
} from '../actions/actions';
import { getURLForItem } from '../lib/api';
import useAnimationFrame from '../hooks/useAnimationFrame';

const StyledCollectionItem = styled.div`
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	padding: 10px 15px 10px 25px;
	position: relative;
	transition: padding-top 330ms ease, background 330ms ease;
	display: ${(props) => (props.visible ? 'flex' : 'none')};

	&:first-child {
		border-top: 0;
	}

	.item-player {
		background: rgba(255, 255, 255, 0.3);
		position: absolute;
		left: 0;
		right: 0;
		top: -1px;
		height: 0px;
		transition: height 330ms ease;

		.item-progress {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			background: #01ffa7;
		}
	}

	&.playing {
		padding-top: 14px;
		background: #01ffa733;
		border-top-color: transparent;

		.item-player {
			height: 4px;
		}
	}

	.item-metadata {
		flex: 1;
		white-space: nowrap;
		overflow: hidden;
		mask-image: linear-gradient(
			to left,
			rgba(0, 0, 0, 0) 0,
			rgba(0, 0, 0, 1) 25px
		);
		position: relative;
		left: -4px;

		.item-id {
			font-size: 10.8px;
			background: rgba(255, 255, 255, 0.2);
			border-radius: 1000px;
			padding: 3.6px 9.6px;
			display: inline-block;
			margin-bottom: 7.2px;
		}

		.item-title {
			font-size: 19.2px;
			position: relative;
			left: 4px;
			margin-bottom: 4px;
		}

		.item-artist {
			font-size: 15px;
			opacity: 0.7;
			position: relative;
			left: 4px;
			line-height: 1.3;
			overflow: visible;
		}
	}

	.item-controls {
		flex: 0 0 100px;
		margin-left: 10px;
		display: flex;

		& > div {
			flex: 1;
			justify-content: center;
			align-items: center;
			display: flex;
		}

		.activate img {
			width: 18px;
			height: 18px;
		}

		.play img {
			width: 11px;
			height: 12px;
		}
	}
`;

function CollectionItem(props) {
	const {
		id,
		title,
		artist,
		name,
		currentlySelected,
		collectionPageActive,
		dispatch,
		collection,
		visible,
	} = props;
	const [isSelected, setIsSelected] = useState(false);
	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);

	const howl = useRef();

	useEffect(() => {
		setIsSelected(currentlySelected === name);
	}, [currentlySelected]);

	useEffect(() => {
		if (howl.current) {
			howl.current.unload();
			howl.current = null;
		}

		setIsPlaying(false);

		if (isSelected) {
			getURLForItem(name).then((url) => {
				howl.current = new Howl({
					src: url,
				});

				howl.current.rate(1);
				setIsPlaying(true);
			});
		}
	}, [isSelected]);

	useEffect(() => {
		if (!howl.current) return;

		if (isPlaying) {
			howl.current.play();
		} else {
			howl.current.pause();
		}
	}, [isPlaying]);

	useEffect(() => {
		if (!collectionPageActive) setIsPlaying(false);
	}, [collectionPageActive]);

	useAnimationFrame(() => {
		if (!howl.current) return;

		const currentTime = howl.current.seek();
		const totalTime = howl.current.duration();

		setProgress(currentTime / totalTime);
	}, [howl]);

	useEffect(() => {
		if (!howl.current) return;

		const a = window.addEventListener('blur', () => {
			howl.current.mute(true);
		});

		const b = window.addEventListener('focus', () => {
			howl.current.mute(false);
		});

		return () => {
			window.removeEventListener('blur', a);
			window.removeEventListener('focus', b);
		};
	}, [howl.current]);

	return (
		<StyledCollectionItem
			className={isSelected ? 'playing' : ''}
			visible={visible}
		>
			<div className="item-player">
				<div
					className="item-progress"
					style={{ width: `${Math.round(progress * 10000) / 100}%` }}
				/>
			</div>
			<div className="item-metadata">
				<div className="item-id">{`No. ${`0000${id}`.substr(-4, 4)}`}</div>
				<div className="item-title">{title}</div>
				<div className="item-artist">{artist}</div>
			</div>
			<div className="item-controls">
				<div
					className="share"
					onClick={() => {
						let text;

						if (collection.length === 0) {
							text = `Ik heb nog geen nummers in mijn collectie op Groef, maar luister nu naar ${title}!`;
						} else if (collection.length === 1) {
							text = `Ik heb al één nummer in mijn collectie op Groef, en luister nu naar ${title}!`;
						} else {
							text = `Ik heb al ${collection.length} nummers in mijn collectie op Groef, en luister nu naar ${title}!`;
						}

						navigator.share({
							title: 'Groef',
							text,
							url: `https://groef.app/?item=${name}`,
						});
					}}
				>
					<img src={ButtonShareSmall} />
				</div>
				<div
					className="activate"
					onClick={() => {
						dispatch(setActiveItem(name));
						dispatch(setCollectionPageActive(false));
						dispatch(setPlayingItem(null));
					}}
				>
					<img src={ButtonActivate} />
				</div>
				<div
					className="play"
					onClick={() => {
						if (isSelected) {
							if (isPlaying) setIsPlaying(false);
							else setIsPlaying(true);
						} else {
							dispatch(setPlayingItem(name));
						}
					}}
				>
					<img src={isPlaying ? ButtonPause : ButtonPlay} />
				</div>
			</div>
		</StyledCollectionItem>
	);
}

function mapStateToProps(state) {
	return {
		currentlySelected: state.playingItem,
		collectionPageActive: state.app.collectionPageActive,
		collection: state.collection,
	};
}

export default connect(mapStateToProps)(CollectionItem);
