import React, { createRef, useEffect } from 'react';
import styled from 'styled-components';
import NoSleep from 'nosleep.js';
import { initDeviceOrientation } from '../lib/deviceOrientation';
import Button from './Button';
import GraphicLogo from '../graphics/graphic_logo.svg';
import GraphicPoweredBy from '../graphics/graphic_powered_by.svg';
import ImageTutorialA from '../graphics/image_tutorial_a.png';
import ImageTutorialB from '../graphics/image_tutorial_b.png';
import ImageTutorialC from '../graphics/image_tutorial_c.png';
import ImageTutorialD from '../graphics/image_tutorial_d.png';
import ImageTutorialE from '../graphics/image_tutorial_e.png';

const noSleep = new NoSleep();

const StyledDeviceOrientationPrompt = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	backdrop-filter: blur(40px);
	-moz-backdrop-filter: blur(40px);

	.pages {
		pointer-events: all;
		height: 100%;
		overflow: hidden;

		.slides {
			display: flex;
			flex-wrap: nowrap;
			height: 100%;

			.page {
				scroll-snap-align: start;
				flex: 0 0 100%;
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				position: relative;

				.content {
					margin: 33px 33px 150px;

					& > div + div {
						margin-top: 25px;
					}
				}

				.bottom {
					position: absolute;
					left: 50%;
					bottom: 78px;
					transform: translateX(-50%);
				}
			}
		}
	}

	.indicators {
		position: absolute;
		left: 50%;
		bottom: 50px;
		transform: translate(-50%, -50%);
		display: flex;
		flex-wrap: nowrap;

		.indicator {
			width: 7px;
			height: 7px;
			margin: 0 5px;
			border-radius: 10px;
			background: #ffffff;
			opacity: 0.15;
			transition: opacity 0.1s ease;

			&.active {
				opacity: 1;
			}
		}
	}
`;

export default (props) => {
	const { onResolve, enableTutorial } = props;

	const pagesRef = createRef();
	const slidesRef = createRef();

	let pTouchLoc = -1;
	let translation = 0;
	let totalMove = 0;
	let slide = 0;

	function doTouch() {
		pagesRef.current.addEventListener('touchstart', (e) => {
			// e.preventDefault();

			pTouchLoc = e.touches[0].screenX;
			totalMove = 0;
			slidesRef.current.style.transition = 'none';
		});

		pagesRef.current.addEventListener('touchmove', (e) => {
			// e.preventDefault();
			const delta = e.touches[0].screenX - pTouchLoc;

			pTouchLoc = e.touches[0].screenX;

			translation += delta;
			totalMove += delta;

			slidesRef.current.style.transform = `translateX(${translation}px)`;
		});

		pagesRef.current.addEventListener('touchend', () => {
			// e.preventDefault();

			if (totalMove > 0.2 * window.innerWidth) {
				slide--;
				slide = Math.max(slide, 0);
			} else if (totalMove < -0.2 * window.innerWidth) {
				slide++;
				slide = Math.min(slide, slidesRef.current.children.length - 1);
			}

			let w = 0;

			for (let i = 0; i < slide; i++) {
				w += slidesRef.current.children[i].clientWidth;
			}

			translation = -w;
			slidesRef.current.style.transition = 'transform .3s ease';
			slidesRef.current.style.transform = `translateX(${translation}px)`;

			[].slice.call(document.querySelectorAll('.indicator')).forEach((e, i) => {
				if (i === slide + 1) e.classList.add('active');
				else e.classList.remove('active');
			});
		});
	}

	useEffect(() => {
		if (pagesRef.current.children[0].children.length > 1) {
			doTouch();

			// const observer = new IntersectionObserver(
			// 	(entries) => {
			// 		const activated = entries.reduce((max, entry) =>
			// 			entry.intersectionRatio > max.intersectionRatio ? entry : max
			// 		);

			// 		if (activated.intersectionRatio > 0) {
			// 			[].slice.call(pagesRef.current.children).forEach((child) => {
			// 				child.indicator.classList.remove('active');
			// 			});
			// 			activated.target.indicator.classList.add('active');
			// 		}
			// 	},
			// 	{
			// 		root: pagesRef.current,
			// 		threshold: 0.5,
			// 	}
			// );

			const indicatorContainer = document.createElement('div');
			indicatorContainer.classList.add('indicators');

			for (let i = 0; i < pagesRef.current.children[0].children.length; i++) {
				const indicator = document.createElement('div');
				indicator.classList.add('indicator');

				if (i === 0) indicator.classList.add('active');

				// pagesRef.current.children[0].children[i].indicator = indicator;
				// observer.observe(pagesRef.current.children[i]);

				indicatorContainer.appendChild(indicator);
			}

			pagesRef.current.parentNode.appendChild(indicatorContainer);
		}
	}, [pagesRef]);

	return (
		<StyledDeviceOrientationPrompt>
			<div className="pages" ref={pagesRef}>
				<div className="slides" ref={slidesRef}>
					{enableTutorial && [
						<div className="page" key={1}>
							<div className="content">
								<div>
									<img src={GraphicLogo} />
								</div>
								<div>
									<p>Welkom bij Groef!</p>
									<p style={{ opacity: 0.7 }}>
										<span style={{ fontSize: '0.75em' }}>
											Swipe
{' '}
											<span style={{ position: 'relative', top: '1px' }}>
												→
											</span>
										</span>
									</p>
								</div>
							</div>
							<div className="bottom">
								<img src={GraphicPoweredBy} />
							</div>
						</div>,
						<div className="page" key={2}>
							<div className="content">
								<div>
									<img src={ImageTutorialA} height="79" />
								</div>
								<div className="description">
									<p>
										Groef is een nieuwe manier om de historische platencollectie
										van het Nederlands Instituut voor Beeld en Geluid digitaal
										te ontdekken.
									</p>
								</div>
							</div>
						</div>,
						<div className="page" key={3}>
							<div className="content">
								<div>
									<img src={ImageTutorialB} height="79" />
								</div>
								<div className="description">
									<p>
										Ontdek de nummers door je smartphone om zijn as te draaien.
									</p>

									<p>
										Probeer je smartphone ook eens op een platenspeler te
										leggen.
									</p>
								</div>
							</div>
						</div>,
						<div className="page" key={4}>
							<div className="content">
								<div>
									<img src={ImageTutorialC} height="79" />
								</div>
								<div className="description">
									<p>
										Voeg nummers toe aan jouw collectie door ze minimaal 10
										seconden af te spelen.
									</p>

									<p>
										Deel nummers met bekenden en help ze hun collectie uit te
										breiden. Krijg jij de collectie compleet?
									</p>
								</div>
							</div>
						</div>,
						<div className="page" key={5}>
							<div className="content">
								<div>
									<img src={ImageTutorialD} height="79" />
								</div>
								<div className="description">
									<p>Zorg dat je device niet op stil staat.</p>
								</div>
								<Button
									onClick={() => {
										initDeviceOrientation().then((status) => onResolve(status));
									}}
								>
									Laten we beginnen!
								</Button>
							</div>
						</div>,
					]}
					{!enableTutorial && (
						<div className="page">
							<div className="content">
								<div>
									<img src={ImageTutorialE} height="79" />
								</div>
								<div>
									<p>
										Welkom terug bij Groef! Zorg dat je smartphone niet op stil
										staat.
									</p>
								</div>
								<Button
									onClick={() => {
										initDeviceOrientation().then((status) => onResolve(status));
										noSleep.enable();
									}}
								>
									Ga verder!
								</Button>
							</div>
							<div className="bottom">
								<img src={GraphicPoweredBy} />
							</div>
						</div>
					)}
				</div>
			</div>
		</StyledDeviceOrientationPrompt>
	);
};
