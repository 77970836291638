import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.div`
	display: inline-block;
	padding: 12px 24px;
	border-radius: 500px;
	border: 1px solid rgba(255, 255, 255, 1);
	font-size: 15.75px;
	/* background-color: rgba(255, 255, 255, 0.2); */
`;

export default (props) => {
	const { children, onClick } = props;

	return <StyledButton onClick={onClick}>{children}</StyledButton>;
};
