import { createGlobalStyle } from 'styled-components';
import GesturesRegular from '../fonts/Gestures-Regular.woff2';

export default createGlobalStyle`
	html, body {
		overflow: hidden;
		background: #000;
	}

	#app {
		overflow: hidden;
	}

	@font-face {
		font-family: "Gestures";
		font-weight: normal;
		font-style: normal;
		src: url(${GesturesRegular});
	}

	body {
		color: #FFF;
		font-family: "Gestures", sans-serif;
	}

	* {
		touch-action: pan-y pan-x;
		user-select: none;
	}

	input {
		user-select: all;
	}

	p {
		font-size: 19px;
		line-height: 28px;

		&+p {
			margin-top: 30px;
		}
		
		&.subheading {
			margin-top: 40px;			
			&+p {
				margin-top: 20px;			
			}
		}
	}

	a, a:active, a:visited, a:hover {
		color: inherit;
	}

	body.landscapeCW {
		#app {
			width: 100vh;
			height: 100vw;
			position: absolute;
			left: 0;
			top: 0;
			transform-origin: 0 0;
			transform: rotate(-90deg) translate(-100%, 0%);
		}
	}

	body.landscapeCCW {
		#app {
			width: 100vh;
			height: 100vw;
			position: absolute;
			left: 0;
			top: 0;
			transform-origin: 0 0;
			transform: rotate(90deg) translate(0%, -100%);
		}
	}
	
`;
