import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

// const gradient = [
// 	{ r: 0, g: 239, b: 156 },
// 	{ r: 126, g: 101, b: 255 },
// 	{ r: 255, g: 175, b: 145 },
// ];

// function sampleGradient(t) {
// 	const s = t * (gradient.length - 1);
// 	const firstStop = Math.floor(s);
// 	const l = s - firstStop;
// 	const lastStop = Math.ceil(s);

// 	return {
// 		r:
// 			(gradient[lastStop].r - gradient[firstStop].r) * l +
// 			gradient[firstStop].r,
// 		g:
// 			(gradient[lastStop].g - gradient[firstStop].g) * l +
// 			gradient[firstStop].g,
// 		b:
// 			(gradient[lastStop].b - gradient[firstStop].b) * l +
// 			gradient[firstStop].b,
// 	};
// }

const emoji = ['🎉', '🌸', '✨'];
const floatEmoji = [
	'👏🏻',
	'👏🏼',
	'👏🏽',
	'👏🏾',
	'👏🏿',
	'💎',
	'💎',
	'💎',
	'💎',
	'💎',
	'👑',
	'👑',
	'👑',
	'👑',
	'👑',
	'🏅',
	'🏅',
	'🏅',
	'🏅',
	'🏅',
];

const StyledCanvas = styled.canvas`
	position: absolute;
	left: 0;
	top: 0;
	box-sizing: border-box;
	pointer-events: none;
`;

export const generator = {
	generate: {
		regular: () => {},
		floating: () => {},
	},
};

export default () => {
	const canvasRef = useRef();
	const pixelRatio = window.devicePixelRatio || 1;

	function init(_canvas) {
		const canvas = _canvas;
		const context = canvas.getContext('2d');
		const particles = [];

		function renderParticles() {
			for (let i = 0; i < particles.length; i++) {
				const p = particles[i];

				// context.lineWidth = p.length * 2 * pixelRatio;
				context.fillStyle = `rgb(0, 0, 0, ${p.opacity})`;

				context.font = `${p.size}px serif`;
				context.textAlign = 'center';
				context.textBaseline = 'middle';

				context.translate(p.x, p.y);

				// context.setTransform(1, p.tiltX, p.tiltY, 1, p.x, p.y);
				context.beginPath();
				context.fillText(p.emoji, 0, 0);
				// context.fillRect(-p.size / 2, -p.size / 2, p.size, p.size);
				// context.lineTo(
				// 	p.x + p.length * pixelRatio,
				// 	p.y + p.length * pixelRatio
				// );
				// context.moveTo(p.x + p.tilt * pixelRatio + p.length * pixelRatio, p.y);
				// context.lineTo(
				// 	p.x + p.tilt * pixelRatio,
				// 	p.y + p.tilt * pixelRatio + p.length * pixelRatio
				// );
				context.stroke();
				context.setTransform(1, 0, 0, 1, 0, 0);
				// context.translate(-p.x, -p.y);
			}
		}

		function clear() {
			context.clearRect(0, 0, canvas.width, canvas.height);
		}

		function updateParticles() {
			const now = Date.now();

			for (let i = particles.length - 1; i >= 0; i--) {
				const p = particles[i];

				const life = (now - p.birth) / p.life;

				if (life > 1) {
					particles.splice(i, 1);
					continue;
				}

				// const tiltAngle = 0.0005 * p.size;

				// p.tiltAngle += tiltAngle;
				// p.tiltAngle += p.tiltSpeed;
				// p.tilt = Math.sin(p.tiltAngle - p.size / 2) * p.size * 2;

				// p.tiltAngle += p.tiltSpeed;

				// p.tiltY = Math.sin(p.tiltAngle) * 0.3;
				// p.tiltX = Math.cos(p.tiltAngle) * 0.3; // Math.sin(Math.PI * 2);

				p.velocity.y += p.gravity;

				p.velocity.x *= 1 - p.friction;
				p.velocity.y *= 1 - p.friction;

				p.x += p.velocity.x / 100;
				p.y += p.velocity.y / 100;

				if (life > 0.95) {
					p.opacity = 1 - (life - 0.95) / 0.05;
				}
			}
		}

		function update() {
			clear();
			updateParticles();
			renderParticles();
		}

		function generateFloatingParticles(n) {
			const now = Date.now();

			for (let i = 0; i < n; i++) {
				const particle = {
					x: Math.random() * canvas.width,
					y: canvas.height + Math.random() * canvas.height,
					velocity: {
						x: 0,
						y: -Math.random() * 1000 - 2000,
					},
					gravity: 0,
					life: 4000,
					birth: now,
					friction: 0,
					// color: sampleGradient(Math.random()),
					size: Math.random() * 50 + 200,
					emoji: floatEmoji[Math.floor(floatEmoji.length * Math.random())],
					// length: Math.random() * 3 + 5,
					// width: Math.random() * 15 + 20,
					// tiltX: 0,
					// tiltY: 0,
					// tiltSpeed: Math.random() * 0.1 + 0.05,
					// tiltAngle: Math.random() * Math.PI * 2,
					opacity: 1,
				};

				particles.push(particle);
			}
		}

		function generateParticles(x, y, offsetY, n) {
			const now = Date.now();

			for (let i = 0; i < n; i++) {
				const angle = Math.random() * (Math.PI - 2) + Math.PI + 1;
				const velocity = Math.random() * 15000 + 3000;

				const particle = {
					x: x * canvas.width,
					y: y * canvas.height + offsetY * canvas.width,
					velocity: {
						x: Math.cos(angle) * velocity,
						y: Math.sin(angle) * velocity,
					},
					gravity: 400,
					life: Math.random() * 600 + 2000,
					birth: now,
					friction: Math.random() * 0.15 + 0.08,
					// color: sampleGradient(Math.random()),
					size: Math.random() * 50 + 200,
					emoji: emoji[Math.floor(emoji.length * Math.random())],
					// length: Math.random() * 3 + 5,
					// width: Math.random() * 15 + 20,
					tiltX: 0,
					tiltY: 0,
					tiltSpeed: Math.random() * 0.1 + 0.05,
					tiltAngle: Math.random() * Math.PI * 2,
					opacity: 1,
				};

				particles.push(particle);
			}
		}

		function loop() {
			update();
			requestAnimationFrame(loop);
		}

		function resize() {
			// console.log(document.querySelector('#app').getBoundingClientRect());

			const _w = window.innerWidth;
			const _h = window.innerHeight;
			const w = Math.min(_w, _h);
			const h = Math.max(_w, _h);

			canvas.width = w * pixelRatio;
			canvas.height = h * pixelRatio;
			canvas.style.width = `${w}px`;
			canvas.style.height = `${h}px`;

			console.log(w, h);
		}

		function addListeners() {
			// window.addEventListener('resize', resize);
			window.addEventListener('orientationchange', resize);
		}

		resize();
		addListeners();
		loop();

		return { regular: generateParticles, floating: generateFloatingParticles };
	}

	useEffect(() => {
		generator.generate = init(canvasRef.current);
	}, [canvasRef]);

	return <StyledCanvas ref={canvasRef} />;
};
